import MDRouter from "@/service/router";
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { Dialog,
// AddressEdit,
Form, Image as VImage, Switch, showToast } from 'vant';
// eslint-disable-next-line no-unused-vars
import { post, get, OSSUpload } from '@/utils/apiBase.js';
import { mapState, useStore } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { getOrderDetail, mdaftersaleApply_order, showVideoList } from "@api/index";
import lowerdev from '@/service/lowerdev';
import { env, domClientWidth, domPixelRatio, domTop, domHeight, domClientHeight } from "md-base-tools/env";
import CompProCard from "@@/after_sale/pro_card.vue";
import { mdToast } from "../../utils/tools";
export default {
  name: 'orderList',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    [Dialog.name]: Dialog.Component,
    VImage,
    Form,
    // eslint-disable-next-line vue/no-unused-components
    // eslint-disable-next-line vue/no-unused-components
    Switch,
    CompProCard
  },
  async setup() {
    const route = useRoute();
    // eslint-disable-next-line no-unused-vars
    const store = useStore();
    const orderId = route.params.id ? route.params.id : null;
    let PInfo = {};
    let address = ref({});
    if (sessionStorage.getItem('product_info')) {
      PInfo = JSON.parse(sessionStorage.getItem('product_info'));
      address = JSON.parse(sessionStorage.getItem('address_info'));
      sessionStorage.clear('product_info');
      sessionStorage.clear('address_info');
    } else {
      const res = await getOrderDetail({
        id: orderId,
        type: "order_id"
      });
      if (res.status !== 0) {
        return showToast({
          type: "text",
          message: res.message,
          onClose: () => {
            window.history.back();
          }
        });
      }
      PInfo = res.data.product_info;
      address = res.data.address_info;
    }
    if (sessionStorage.getItem('address')) {
      address = JSON.parse(sessionStorage.getItem('address'));
    }
    return {
      PInfo,
      address,
      orderId
    };
  },
  data() {
    return {
      domPixelRatio,
      // eslint-disable-next-line no-undef
      showPicker: false,
      readyBtn: false,
      dialogImgShow: false,
      orderRefundShow: false,
      dialog_btn: [{
        name: '少件/漏发/错发',
        type: 1
      }, {
        name: '破损/磕角/污渍/变形/裂痕',
        type: 2
      }, {
        name: '质量问题',
        type: 3
      }, {
        name: '无物流信息/物流信息停滞',
        type: 4
      }],
      imgBigUrl: '',
      show: false,
      addresid: '',
      dialogShow: false,
      env: env,
      quhao: false,
      idx: 0,
      postal_code: '',
      codeList: countryCode.codeList,
      payList: countryCode.payList,
      street: '',
      apply_reson_name: '',
      from: {
        apply_reson_type: '',
        apply_reson_content: '',
        order_id: this.orderId,
        address: this.address
      },
      isMobile: env.isMobile(),
      columns: [],
      columnsStreet: [],
      fileList: [],
      placeholder: '~@assets/images/orderlist/default_1x1@2x.png'
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  beforeMount() {
    if (sessionStorage.getItem('miniProgram') === 'weixin') {
      lowerdev.dynamicScript('https://res.wx.qq.com/open/js/jweixin-1.4.0.js');
    }
  },
  mounted() {
    lowerdev.addbind(window, 'scroll', this.reloadScroll);
    // eslint-disable-next-line eqeqeq
    this.miniProgram = sessionStorage.getItem('miniProgram') == 'weixin';
    if (sessionStorage.getItem('formDataA')) {
      const data = JSON.parse(sessionStorage.getItem('formDataA'));
      this.apply_reson_name = data.apply_reson_name;
      this.from = data.from;
      this.from.address = this.address;
      this.fileList = data.fileList;
      sessionStorage.setItem('formDataA', '');
    }
  },
  watch: {
    from: {
      handler(newValue) {
        if (newValue.apply_reson_type && newValue.apply_reson_content) {
          this.readyBtn = true;
        } else {
          this.readyBtn = false;
        }
      },
      deep: true
    }
  },
  beforeUnmount() {
    lowerdev.removebind(window, 'scroll', this.reloadScroll);
  },
  methods: {
    toAddressList() {
      sessionStorage.setItem('formDataA', JSON.stringify({
        apply_reson_name: this.apply_reson_name,
        from: this.from,
        fileList: this.fileList
      }));

      // eslint-disable-next-line
      graft.app({
        functionName: "chooseAddress",
        functionParams: {},
        callBack: e => {
          if (e.errorCode == 0) {
            this.address = e.data;
            this.from.address = e.data;
          } else {
            mdToast('请升级app版本');
          }
        },
        webFun: () => {
          this.$router.push('/address/address_list');
        }
      });
    },
    appUpdate() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "chooseMedia",
        functionParams: {
          type: 3,
          count: 6 - this.fileList.length
        },
        callBack: ({
          errorCode,
          data
        }) => {
          if (errorCode == 0) {
            if (data.type == 1) {
              data.image_list.forEach(e => {
                this.fileList.push({
                  file_type: 'img',
                  url: e
                });
              });
            } else {
              console.log(222, data);
              const _this = this;
              const index = this.fileList.length;
              this.fileList.push({
                file_type: 'video',
                url: data.video.video_url,
                video_cover: data.video.video_cover
              });
              let num = 0;
              const fun = () => {
                const IMG = new Image();
                IMG.onload = function () {
                  _this.fileList[index].loaded = true;
                  _this.fileList[index].video_cover = data.video.video_cover;
                  console.log('成功', _this.fileList);
                };
                IMG.onerror = function () {
                  if (num < 5) {
                    setTimeout(() => {
                      fun();
                      num++;
                    }, 2000);
                  } else {
                    _this.fileList[index].loaded = true;
                    _this.fileList[index].video_cover = "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png";
                  }
                };
                IMG.src = data.video.video_cover;
              };
              fun();
            }
          }
        }
      });
    },
    async openBig(item) {
      this.imgBigUrl = item;
      if (item.file_type == 'video') {
        await this.showVideoListFn(item.url);
        this.dialogImgShow = true;
      } else {
        this.dialogImgShow = true;
      }
    },
    async showVideoListFn(url) {
      const {
        data
      } = await showVideoList({
        url
      });
      this.imgBigUrl.videoUrl = data.list.OD;
    },
    deleteImg(idx) {
      this.fileList.splice(idx, 1);
    },
    async readyBtnSubmit() {
      if (!this.readyBtn) throw new Error('请填写必填项');
      // this.from.address = JSON.stringify(this.from.address)
      const address = JSON.stringify(this.from.address);
      const obj = JSON.parse(JSON.stringify({
        ...this.from,
        address: address
      }));
      obj.apply_attachment = JSON.stringify(this.fileList);
      const {
        status,
        message
      } = await mdaftersaleApply_order(obj);
      if (status) {
        showToast({
          type: "text",
          message: message
        });
      } else {
        showToast({
          type: "text",
          message: '操作成功',
          onClose: () => {
            // 跳转至售后详情
            const url = `/after_sale/detail?order_id=${this.orderId}&full_screen=true`;
            MDRouter.Link(url, 'wap');

            // eslint-disable-next-line no-undef
            graft.app({
              functionName: "closeController",
              webFun: () => {
                history.back();
              }
            });
          }
        });
      }
    },
    async afterRead(event) {
      if (event.length) {
        event.forEach((e, ix) => {
          this.fileList.push({
            file_type: e.file.type.includes('video') ? 'video' : 'img'
          });
          if (e.file.type.includes('video')) {
            let option = {
              status: 1,
              success: (item, cover, idx, bol, list) => {
                this.onSuccess({
                  item,
                  idx: this.fileList.length - 1 + ix,
                  list,
                  cover
                });
              },
              error: (e, msg) => {
                showToast({
                  type: "text",
                  message: msg,
                  onClose: () => {}
                });
              }
            };
            let fileList = [e.file];
            OSSUpload(option, fileList, function (p) {
              console.log(p);
            });
          } else {
            let option = {
              status: 0,
              success: (item, idx, bol, list, info) => {
                this.onSuccess({
                  item,
                  idx: this.fileList.length - 1 + ix,
                  list,
                  info
                });
              },
              error: (e, msg) => {
                showToast({
                  type: "text",
                  message: msg,
                  onClose: () => {}
                });
              }
            };
            let fileList = [e.file];
            OSSUpload(option, fileList);
          }
        });
      } else {
        this.fileList.push({
          file_type: event.file.type.includes('video') ? 'video' : 'img'
        });
        if (event.file.type.includes('video')) {
          let option = {
            status: 1,
            success: (item, cover, idx, bol, list) => {
              console.log(cover);
              this.onSuccess({
                item,
                idx: this.fileList.length - 1,
                list,
                cover
              });
            },
            error: (e, msg) => {
              console.log(e);
              showToast({
                type: "text",
                message: msg,
                onClose: () => {}
              });
              event.target.value = '';
            }
          };
          let fileList = [event.file];
          OSSUpload(option, fileList, function (p) {
            console.log(p);
          });
        } else {
          let option = {
            status: 0,
            success: (item, idx, bol, list, info) => {
              this.onSuccess({
                item,
                idx: this.fileList.length - 1,
                list,
                info
              });
            },
            error: (e, msg) => {
              console.log(e);
              showToast({
                type: "text",
                message: msg,
                onClose: () => {}
              });
              event.target.value = '';
            }
          };
          let fileList = [event.file];
          OSSUpload(option, fileList);
        }
      }
    },
    async onSuccess(item) {
      this.fileList[item.idx].url = item.item;
      console.log(JSON.stringify(this.fileList));
      if (this.fileList[item.idx].file_type == 'video') {
        const _this = this;
        let num = 0;
        const fun = () => {
          const IMG = new Image();
          IMG.onload = function () {
            _this.fileList[item.idx].loaded = true;
            _this.fileList[item.idx].video_cover = item.cover;
            console.log('成功', _this.fileList);
          };
          IMG.onerror = function () {
            if (num < 5) {
              setTimeout(() => {
                fun();
                num++;
              }, 2000);
            } else {
              _this.fileList[item.idx].loaded = true;
              _this.fileList[item.idx].video_cover = "https://s.moimg.net/m/img/placeholder/default_1x1@2x.png";
            }
          };
          IMG.src = item.cover;
        };
        fun();
      }
    },
    // 重新加载图片
    loadImg(index, src) {
      function unit(index, src) {
        let IMG = new Image();
        IMG.src = src;
        IMG.onLoad = () => {
          this.fileList[index]._video_cover = src;
        };
        IMG.onerror = () => {
          setTimeout(() => {
            unit(index, src);
          }, 3000);
        };
      }
      unit(index, src);
    },
    dialogBack() {
      this.orderRefundShow = false;
    },
    op(type) {
      this.orderRefundShow = false;
      this.from.apply_reson_type = type.type;
      this.apply_reson_name = type.name;
    },
    onConfirmquhao({
      selectedOptions
    }) {
      this.quhao = false;
      this.from.code_posi = selectedOptions[0].text;
    },
    onSubmit() {
      this.addAddress();
    },
    onClose() {
      this.show = false;
    },
    onCancel(e) {
      console.log(e);
    },
    onConfirm(e) {
      this.pro_posi = e[0].name;
      this.province_id = e[0].id;
      this.city_posi = e[1].name;
      this.city_id = e[1].id;
      this.region_posi = e[2].name;
      this.county_id = e[2].id;
      this.from.area = `${e[0].name}/ ${e[1].name}/ ${e[2].name}`;
      this.new_address_street();
      this.show = false;
    },
    StreetChange(e) {
      this.street = e.name;
      this.from.street = e.name;
      this.showPicker = false;
    },
    close() {
      this.dialogShow = true;
    },
    dropBack() {
      history.back();
      // if (history.length > 1) {
      //   this.$router.back(-1)
      // } else {
      //   lowerdev.openUrl()
      // }
    },
    reloadScroll() {
      const localTop = domTop();
      if (this.loading) {
        return false;
      }
      if (localTop && domHeight() < localTop + domClientHeight() + 50) {
        this.loading = true;
        this.page_index = this.page_index + 1;
      }
    }
  }
};
const countryCode = {
  codeList: [{
    id: '86',
    name: '中国大陆'
  }, {
    id: '852',
    name: '中国香港'
  }, {
    id: '853',
    name: '中国澳门'
  }, {
    id: '886',
    name: '中国台湾'
  }, {
    id: '61',
    name: '澳大利亚'
  }, {
    id: '1',
    name: '加拿大'
  }, {
    id: '33',
    name: '法国'
  }, {
    id: '49',
    name: '德国'
  }, {
    id: '62',
    name: '印度尼西亚'
  }, {
    id: '39',
    name: '意大利'
  }, {
    id: '81',
    name: '日本'
  }, {
    id: '82',
    name: '韩国'
  }, {
    id: '60',
    name: '马来西亚'
  }, {
    id: '64',
    name: '新西兰'
  }, {
    id: '31',
    name: '荷兰'
  }, {
    id: '63',
    name: '菲律宾'
  }, {
    id: '7',
    name: '俄罗斯'
  }, {
    id: '65',
    name: '新加坡'
  }, {
    id: '46',
    name: '瑞典'
  }, {
    id: '66',
    name: '泰国'
  }, {
    id: '44',
    name: '英国'
  }, {
    id: '380',
    name: '乌克兰'
  }, {
    id: '1',
    name: '美国'
  }, {
    id: '84',
    name: '越南'
  }],
  payList: [{
    text: '+86  中国大陆',
    value: 86
  }, {
    text: "+852 中国香港",
    value: 852
  }, {
    text: "+853 中国澳门",
    value: 853
  }, {
    text: "+886 中国台湾",
    value: 886
  }, {
    text: "+61  澳大利亚",
    value: 61
  }, {
    text: "+1   加拿大",
    value: 1
  }, {
    text: "+33  法国",
    value: 33
  }, {
    text: "+49  德国",
    value: 49
  }, {
    text: "+62  印度尼西亚",
    value: 62
  }, {
    text: "+39  意大利",
    value: 39
  }, {
    text: "+81  日本",
    value: 81
  }, {
    text: "+82  韩国",
    value: 82
  }, {
    text: "+60  马来西亚",
    value: 60
  }, {
    text: "+64  新西兰",
    value: 64
  }, {
    text: "+31  荷兰",
    value: 31
  }, {
    text: "+63  菲利宾",
    value: 63
  }, {
    text: "+7   俄罗斯",
    value: 7
  }, {
    text: "+65  新加坡",
    value: 65
  }, {
    text: "+46  瑞典",
    value: 46
  }, {
    text: "+66  泰国",
    value: 66
  }, {
    text: "+44  英国",
    value: 44
  }, {
    text: "+380 乌克兰",
    value: 380
  }, {
    text: "+1   美国",
    value: 1
  }, {
    text: "+84  越南",
    value: 84
  }],
  payCode: [86, 852, 853, 886, 61, 1, 33, 49, 62, 39, 81, 82, 60, 64, 31, 63, 7, 65, 46, 66, 44, 380, 1, 84]
};